var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"dashboard"}},[_c('v-card',{staticClass:"custom-border border pa-2"},[_c('v-card-title',[_c('h4',{staticClass:"poppins secondary-1--text fw600"},[_vm._v("Dashboard")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"text":"","small":""},on:{"click":function($event){_vm.gallery =  true}}},[_c('v-icon',{class:_vm.gallery ? 'primary--text' : 'secondary-2--text',attrs:{"color":""}},[_vm._v(" mdi-view-gallery-outline ")])],1),_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"text":"","small":""},on:{"click":function($event){_vm.gallery = false}}},[_c('v-icon',{class:!_vm.gallery ? 'primary--text' : 'secondary-2--text',attrs:{"color":""}},[_vm._v(" mdi-format-list-bulleted ")])],1)],1)],1),_c('section',{staticClass:"px-3 d-flex flex-wrap align-center justify-space-between"},[_c('SearchBar',{attrs:{"placeholder":'Search course',"outlined":true,"value":_vm.search},on:{"update:value":function($event){_vm.search=$event},"clear":_vm.clearSearch,"search":_vm.onSearch}}),_c('FilterMenu',{attrs:{"initialFilter":_vm.filter},on:{"resetFilters":() => {
            _vm.minDate = ''
            _vm.maxDate = ''
            _vm.resetFilters()
          },"applyFilters":() => { _vm.page = 1, _vm.updateQuery() }}},[_c('v-sheet',{staticClass:"overflow-y-auto scroller",attrs:{"max-height":"300"}},[_c('div',{staticClass:"mt-3"},[_c('FormLabel',{staticClass:"mb-1",attrs:{"label":'COURSE CREATION DATE (YYYY-MM-DD)'}})],1),_c('label',{staticClass:"caption"},[_vm._v("FROM:")]),_c('FormDatePicker',{attrs:{"val":_vm.filter.created_from,"max":_vm.maxDate,"class_":"col-11 mb-3"},on:{"apply":(e) => {
                  _vm.filter.created_from = e
                  _vm.minDate = e
              }}}),_c('label',{staticClass:"caption"},[_vm._v("TO:")]),_c('FormDatePicker',{attrs:{"val":_vm.filter.created_to,"min":_vm.minDate,"class_":"col-11 mb-3"},on:{"apply":(e) => {
                  _vm.filter.created_to = e
                  _vm.maxDate = e
              }}})],1)],1)],1),(_vm.loading)?_c('section',{staticClass:"px-3 mt-3"},[_c('LinearProgress')],1):_vm._e(),(_vm.courses.length>0)?_c('v-card-text',{staticClass:"d-flex",class:[_vm.$vuetify.breakpoint.mobile && 'justify-center', _vm.gallery ? 'flex-wrap' : 'flex-column']},[_vm._l((_vm.courses),function(item,i){return (_vm.gallery)?_c('CourseCardGallery',{key:item.id,staticClass:"ma-3",attrs:{"i":i,"item":item,"gallery":_vm.gallery},on:{"fetchCourses":_vm.getInstructorDashboardCoursesAction}}):_vm._e()}),_vm._l((_vm.courses),function(item,i){return (!_vm.gallery)?_c('CourseCardList',{key:item.id,staticClass:"ma-3",attrs:{"i":i,"item":item},on:{"fetchCourses":_vm.getInstructorDashboardCoursesAction}}):_vm._e()})],2):_vm._e(),(_vm.courses.length === 0 && !_vm.loading)?_c('v-sheet',{staticClass:"d-flex flex-column align-center justify-center pb-10",attrs:{"height":"500"}},[_c('div',{},[_c('v-img',{attrs:{"max-width":"250","src":require('@/assets/default/empty_dashboard.png')}})],1),_c('h3',{staticClass:"text-center poppins secondary-1--text fw600 my-5"},[_vm._v("You currently have no existing courses.")]),_c('div',{staticClass:"text-decoration-underline f14 poppins primary--text",on:{"click":function($event){return _vm.$emit('createCourse')}}},[_vm._v("Create Course")])]):_vm._e(),_c('FormPagination',{attrs:{"pageCount":_vm.pageCount,"page":_vm.page,"paginate":_vm.paginationPaginate},on:{"page":_vm.onPageChange,"paginate":_vm.onPaginateChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }